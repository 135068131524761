import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import HelpIcon from '@mui/icons-material/Help';
import Tooltip from '@mui/material/Tooltip';
import Slider from '@material-ui/core/Slider';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import Input from '@material-ui/core/Input';
import DrawerCustom from '../component/Drawer';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
//import TextareaAutosize from '@mui/base/TextareaAutosize';
import Divider from "@mui/material/Divider";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import WarningIcon from '@mui/icons-material/Warning';
import ListItemIcon from '@mui/material/ListItemIcon';
//import { DataGrid } from '@mui/x-data-grid';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { styled } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import ListSubheader from '@mui/material/ListSubheader';
import Fab from '@mui/material/Fab';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import EnhancedTable from './DenySelfServiceTable';
import { setGlobalState, useGlobalState } from '../state';
import CallExclusionForm from './CallExclusionForm';
import EditButtons from './EditButtons'
const drawerWidth = 240;


export default function OutboundContacts() {
  
  const outbound_contact_data = async () => {
   const params = {"name": "all"}
   const outboundDataRequest = await axios.get('/api/az_func_outbound_contacts', {params})
   console.log(outboundDataRequest)
  }
  

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <DrawerCustom />
      <EditButtons sx={{ marginTop: '-3%' }} />

      <Accordion sx={{ width: "100%" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="uiSubHeaderFont" id="accordionLabel">
              Call Exclusion
            </Typography>
            <Tooltip
              title="CAF Loan numbers that are excluded from the dialer list no longer fits the dialer requirements for a phone call. "
              sx={{ paddingTop: "0", color: '#febd12' }}
            >
              <IconButton>
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </AccordionSummary>
          <AccordionDetails sx={{ width: "100%" }}>
          <CallExclusionForm />
          </AccordionDetails>
        </Accordion>
    </Box >
  );
}