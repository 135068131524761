import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import '../App.css';
import { Redirect } from "react-router-dom";
import CCTlogo from '../CARMAXCCT_medium.png';
import CarMaxlogo from '../CarMax-Logo.png';
import Image from "../bck5.png";
import Divider from "@mui/material/Divider";
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import CCTLargelogo from '../CARMAXCCT_medium.png';
import KmxLogo from '../CarMax-Logo.png';


export default function Signin() {
  const styles = {
    carmaxLot: {
      backgroundImage: `url(${Image})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      minWidth: '100%',
      minHeight: '100%',
      position: 'fixed',
    },
    kmxLogo: {
      maxWidth: 400,
      maxHeight: 250
    },
    text: {
      textShadow: '1px 1px 2px black'
    }
  };


  return (

    <div className='SignInBackground' style={styles.carmaxLot} >
      <div >

        {localStorage.getItem('isAuthenticated') === "true" ?
          <Redirect to="/configuration-cct-main" />
          :
          <div className="signinCard">
            <Grid container xs={12}>

              <Grid item xs={12}>
                <center>
                  <img src={KmxLogo} className='kmxlogoSignin'></img>
                </center>
              </Grid>
              <Grid item xs={12}>
                <center>
                  <Grid item xs={12}><img src={CCTLargelogo} className='signInLogo'></img></Grid>
                  <Grid item xs={12}><Typography variant="h6" sx={{ color: 'black', marginBottom: '5%' }} >Contact Center Technology Web App</Typography></Grid>
                </center>
              </Grid>
              <Grid item xs={12}>
                {/* <Grid item xs={3}> <img src={CCTlogo} alt="CCTLogo" /> </Grid>
                      <Grid item xs={3}> <img src={CarMaxlogo} alt="CarMax Logo" style={styles.kmxLogo}/> </Grid> */}
                <Grid item xs={12}>
                  <center>
                    {!process.env.REACT_APP_ENV.localeCompare("production") ?

                      <div className="loginButton"><a href="/.auth/login/carmax_prod?post_login_redirect_uri=/loginInfoRedirect"><Button id="signInProd" sx={{ color: '#053361' }} level="primary" type="submit"><Typography variant="h6">Login </Typography></Button></a></div>
                      :
                      <div className="loginButton"><a href="/.auth/login/carmax?post_login_redirect_uri=/loginInfoRedirect"><Button id="signIn" sx={{ color: '#053361' }} level="primary" type="submit"><Typography variant="h6">Login</Typography></Button></a></div>}
                    <Divider>
                      <Chip label="OR" />
                    </Divider>
                    <Button className='loginButton' sx={{ marginBottom: '10%' }}><a href="https://forms.office.com/r/CmipJmkEMx"><Typography variant="h6">Request Access</Typography></a></Button>
                  </center>
                </Grid>
              </Grid>
            </Grid>

          </div>
        }
      </div>
    </div>
  )
}