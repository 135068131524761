import { createGlobalState } from 'react-hooks-global-state';
const { setGlobalState, useGlobalState } = createGlobalState({
  imConfigData: {},
  workFlowData: {},
  workFlowDataBody: {
    profileName: "PhoneScoring",
    userName: localStorage.getItem("username"),
    scores: []
  },
waitTimebody: {
  profileName: "RolloverCallByWaitTime",
  userName: localStorage.getItem("username"),
  times: []
},
phoneScoreBody: {
  collectionName: "inbound_voice_general",
  updateFlag: false,
  userName: localStorage.getItem("username"),
  newValues:{},
  originalValues:{}
},
newWaitTimeBody: {
  collectionName: "inbound_voice_rollover",
  updateFlag: false,
  userName: localStorage.getItem("username"),
  newValues: {},
  originalValues: {}
},
unoBody: {
  collectionName: "work_item_general",
  updateFlag: false,
  userName: localStorage.getItem("username"),
  newValues: {},
  originalValues:{}
},
percentThresholdBody: {
  collectionName: "work_item_routing_override",
  updateFlag: false,
  userName: localStorage.getItem("username"),
  newValues: {},
  originalValues: {}
},
configUpdateBody: [],

callExclusionBody: {
  collectionName: "work_item_routing_override",
	userName: localStorage.getItem("username"),
  newValues: []
},
exclusionInputArray: [],
  firstName: '',
  callExclusionValue: '',
  lowScore: '',
  medScore: '',
  highScore: '',
  originalLowScore: '',
  originalMedScore: '',
  originalHighScore: '',
  initialPriority_Low: '',
  maxPriority_Low: '',
  originalInitialPriority_Low: '',
  originalMaxPriority_Low: '',
  waitTimeThreshMin: '',
  rollOverWaitTime: '',
  originalRollOverWaitTime: '',
  initialPriority_Medium: '',
  originalInitialPriority_Medium: '',
  editMode: false,
  max_num_executions: '',
  max_num_sms_followups: '',
  minutes_until_followup: '',
  store_sms_start_offset: '',
  store_sms_stop_offset: '',
  seconds_failure_retry_wait: '',
  max_failure_retries: '',
  sms_phone_number_tracking_expiration: '',
  task: '',
  webLead: '',
  customerInteraction: '',
  financeSelfService: '',
  global: '',
  sendSms: '',
  web_lead_auto_nuture_check_enabled: '',
  web_lead_non_routing_check_enabled: '',
  web_lead_self_progress_check_enabled: '',
  phone_score_array: [],
  initial_priority_array: [],
  max_priority_array: [],
  wait_time_array: [],
  //_________________________________
  cafGlobalEnable: '',
  originalCafGlobalEnable: '',
  maxCallHoldingLower: '',
  originalMaxCallHoldingLower: '',
  maxCallHoldingUpper: '',
  originalMaxCallHoldingUpper: '',
  maxWaitLow: '',
  originalMaxWaitLow: '',
  maxWaitUpper: '',
  originalMaxWaitUpper: '',
//Project Uno Variables
taskHighThreshold: '',
taskLowThreshold: '',
webLeadHighThreshold: '',
webLeadLowThreshold: '',
unoGlobalEnable: '',
disableSave: false,
exclusionDisableSave: false,
exclusionInput: '',
midScoreThresholdTask: '',
midScoreThresholdWebLead: '',
unoPercentage: '',
ruleIndex: '',
attributeIndex: '',
//CAF IM Controls
card_pay_max: '',
original_card_pay_max: '',
card_pay_min: '',
original_card_pay_min: '',
check_fee_allowed: '',
original_check_fee_allowed: '',
check_pay_max: '',
original_check_pay_max: '',
check_pay_min: '',
original_check_pay_min: '',
csc_max_call_holding_exceeded: '',
original_csc_max_call_holding_exceeded: '',
csc_max_call_holding_lower: '',
original_csc_max_call_holding_lower: '',
csc_max_call_holding_upper: '',
original_csc_max_call_holding_upper: '',
csc_max_wait_exceeded: '',
original_csc_max_wait_exceeded: '',
csc_max_wait_lower: '',
original_csc_max_wait_lower: '',
csc_max_wait_upper: '',
original_csc_max_wait_upper: '',
csc_ro_max_wait_exceeded: '',
original_csc_ro_max_wait_exceeded: '',
csc_ro_max_wait_lower: '',
original_csc_ro_max_wait_lower: '',
csc_ro_max_wait_upper: '',
original_csc_ro_max_wait_upper: '',
csc_wg_max_calls_holding_exceeded: '',
original_csc_wg_max_calls_holding_exceeded: '',
csc_wg_max_calls_holding_lower: '',
original_csc_wg_max_calls_holding_lower: '',
csc_wg_max_calls_holding_upper: '',
original_csc_wg_max_calls_holding_upper: '',
csc_wg_max_wait_exceeded: '',
original_csc_wg_max_wait_exceeded: '',
csc_wg_max_wait_lower: '',
original_csc_wg_max_wait_lower: '',
csc_wg_max_wait_upper: '',
original_csc_wg_max_wait_upper: '',
cspd_max_wait_exceeded: '',
original_cspd_max_wait_exceeded: '',
cspd_max_wait_lower: '',
original_cspd_max_wait_lower: '',
cspd_max_wait_upper: '',
original_cspd_max_wait_upper: '',
csr_wg_max_calls_holding_exceeded: '',
original_csr_wg_max_calls_holding_exceeded: '',
csr_wg_max_calls_holding_lower: '',
original_csr_wg_max_calls_holding_lower: '',
csr_wg_max_calls_holding_upper: '',
original_csr_wg_max_calls_holding_upper: '',
caf_deny_self_service: '',
original_caf_deny_self_service: '',
exclusionInputBody: '',
outboundDataObject: {},
validationChecker: false,
invalidArr: [],
notFoundDialer: false,
newValues: {},
errorArray: [],
alertModal: false,
exclusionDataError: false,
amountErr: false,
lowScore_Test: '',
medScore_Test: '',
highScore_Test: '',
initialPriority_Low_Test: '',
initialPriority_Medium_Test: '',
maxPriority_Low_Test: '',
maxCallHoldingLower_Test: '',
maxCallHoldingUpper_Test: '',
midScoreThresholdTask_Test: ''
});

export {useGlobalState, setGlobalState};