import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import '../App.css';
import Image from "../SignOutBackgroud.webp";
import Grid from '@mui/material/Grid';
import CCTLargelogo from '../CARMAXCCT_medium.png';
import KmxLogo from '../CarMax-Logo.png';

export default function Logout() {
    const styles = {
        carmaxLot: {
            backgroundImage: `url(${Image})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            minWidth: '100%',
            minHeight: '100%',
            position: 'fixed',
        }
    }

    return (
        <div className='SignInBackground' style={styles.carmaxLot}>
            <div className="signinCard">
                <Grid container xs={12}>

                    <Grid item xs={12}>
                        <center>
                            <img src={KmxLogo} className='kmxlogoSignin'></img>
                        </center>
                    </Grid>
                    <Grid item xs={12}>
                        <center>
                            <Grid item xs={12}><img src={CCTLargelogo} className='signInLogo'></img></Grid>
                            <Grid item xs={12}><Typography variant="h6" sx={{ color: 'black', marginBottom: '5%' }} >Contact Center Technology Web App</Typography></Grid>
                        </center>
                    </Grid>
                    <Grid item xs={12}>
                        {/* <Grid item xs={3}> <img src={CCTlogo} alt="CCTLogo" /> </Grid>
                      <Grid item xs={3}> <img src={CarMaxlogo} alt="CarMax Logo" style={styles.kmxLogo}/> </Grid> */}
                        <Grid item xs={12}>
                            <center>
                                <div className="loginButton"><a href="/"><Button id="signIn" sx={{ color: '#053361', width: '100%' }} level="primary" type="submit">Return to Sign-in Page</Button></a></div>
                            </center>
                        </Grid>
                    </Grid>
                </Grid>

            </div>
        </div>     
    )
}
