import React, { useState } from 'react';

export default function Main() {
    const [user, setUser] = useState("")

    async function getUserInfo() {
        const response = await fetch('/.auth/me');
        const payload = await response.json();
        const { clientPrincipal } = payload;
        setUser(clientPrincipal.userDetails)
        return clientPrincipal;
    }
    return (
        <h1>
            Welcome, { user }
        </h1>
    )
}

