import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import './index.css';
import { CssBaseline } from '@mui/material';
import Box from '@mui/material/Box';
import Signin from './component/Signin';
import LandingPage from './component/LandingPage';
import Main from './component/Main';
import ConfigurationCAF from './component/ConfigurationCAF';
import ConfigurationCR from './component/ConfigurationCR';
import CollectLoginInfo from './component/CollectLoginInfo';
import ConfigurationCFR from './component/ConfigurationCFR';
import ConfigurationWFM from './component/ConfigurationWFM';
import Logout from './component/Logout';
import axios from 'axios';
import Slide from '@mui/material/Slide';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import PropTypes from 'prop-types';
import DrawerCustom from './component/Drawer';
import MonitoringWFM from './component/MonitoringWFM';

//import sendAuditLog from './func_splunk_sbq_messenger';
//const splunk = require('../api/app_functions/func_splunk_sbq_messenger.js');
//const splunk = require('../api/az_func_send_splunk_data')
function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};




export default function App(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [user, setUser] = useState("")

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  useEffect(() => {
    async function getUserInfo() {
      const response = await fetch('/.auth/me');
      const payload = await response.json();
      const { clientPrincipal } = payload;
      if (clientPrincipal == null) {
        localStorage.setItem('isAuthenticated', 'false');
        localStorage.setItem('username', " ");
        setUser(" ")
      }
      else if (response.status === 200) {
        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('username', clientPrincipal.userDetails);
        setUser(clientPrincipal.userDetails)

      }
      return clientPrincipal;
    }
    getUserInfo();
    
  })

  useEffect(() => {
    setInterval(() => {
      clearSession()
      window.location.href = "/.auth/logout?post_logout_redirect_uri=/logout"
    }, 3600000)
  })


  async function clearSession() {
    let userName = localStorage.getItem('username')
    localStorage.clear();
    handleClose();
    let event = {
      user: userName,
      time: new Date(Date.now()).toISOString(),
      action: "Admin UI Log-Out Successful",
    }
    // let result = await fetch('../../api/az_func_send_splunk_data', {
    //   method: 'post',
    //   body: event,
    //   headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    // })
    let result = await axios.post('/api/send-splunk-data', { event },
      { headers: { 'Content-type': 'application/x-www-form-urlenconded' } })
    //await splunk.sendAuditLog(event);

    // let context = {}
    // let result = await splunk(context,event)
    //
  }


  return (
    <Router>

      {window.location.pathname !== '/Logout' && window.location.href.indexOf("configuration") > -1 ?
        <React.Fragment>
          <Box sx={{ display: 'flex' }}>
          <CssBaseline />
            <DrawerCustom />
            </Box>
        </React.Fragment>
        : ""}

      <main>
        <Switch>
          <Route exact path="/" component={Signin} />
          <Route exact path="/main" component={Main} />
          <Route exact path="/configuration-wfm" component={ConfigurationWFM} />
          <Route exact path="/configuration-cfr" component={ConfigurationCFR} />
          <Route exact path="/configuration-caf" component={ConfigurationCAF} />
          <Route exact path="/loginInfoRedirect" component={CollectLoginInfo} />
          <Route exact path="/configuration-cr" component={ConfigurationCR} />
          <Route exact path="/configuration-cct-main" component={LandingPage} />
          <Route exact path="/monitoring-wfm" component={MonitoringWFM} />
          <Route exact path="/logout" component={Logout} />
        </Switch>
      </main>
    </Router>
  );
}