import React, { useState, useEffect, createContext } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import HelpIcon from '@mui/icons-material/Help';
import Tooltip from '@mui/material/Tooltip';
import Slider from '@material-ui/core/Slider';
import IconButton from '@mui/material/IconButton';
import Input from '@material-ui/core/Input';
import Switch from '@mui/material/Switch';
import { setGlobalState, useGlobalState } from '../state';
import axios from 'axios';
import DrawerCustom from '../component/Drawer';

const drawerWidth = 240;

export default function ConfigurationCFR() {

  const getR2tdata = async () => {
    let params = { name: "work_item_r2t" }
    let result = await axios.get('/api/az_func_im_config', { params });

    if (result.data === null || result.data === "") {
      result = { data: "No record found with that name" }
    }
    return result
  }

  const setR2tValues = async () => {
    let result = await getR2tdata();
    setGlobalState("max_num_executions", result.data.data.general.max_num_executions)
    setGlobalState("max_num_sms_followups", result.data.data.general.max_num_sms_followups)
    setGlobalState("minutes_until_followup", result.data.data.general.minutes_until_followup)
    setGlobalState("store_sms_start_offset", result.data.data.general.store_sms_start_offset)
    setGlobalState("store_sms_stop_offset", result.data.data.general.store_sms_stop_offset)
    setGlobalState("seconds_failure_retry_wait", result.data.data.general.seconds_failure_retry_wait)
    setGlobalState("max_failure_retries", result.data.data.general.max_failure_retries)
    setGlobalState("sms_phone_number_tracking_expiration", result.data.data.general.sms_phone_number_tracking_expiration)
    setGlobalState("task", result.data.data.volume_dials.Task)
  }

  const setR2tLegacyCntrls = async () => {
    let result = await getR2tdata();
    setGlobalState("web_lead_auto_nuture_check_enabled", result.data.data.legacy_controls.web_lead_auto_nuture_check_enabled)
    setGlobalState("web_lead_non_routing_check_enabled", result.data.data.legacy_controls.web_lead_non_routing_check_enabled)
    setGlobalState("web_lead_self_progress_check_enabled", result.data.data.legacy_controls.web_lead_self_progress_check_enabled)
  }


  const [max_num_executions, setMax_num_executions] = useGlobalState('max_num_executions');
  const [max_num_sms_followups, setMax_num_sms_followups] = useGlobalState('max_num_sms_followups');
  const [minutes_until_followup, setMinutes_until_followup] = useGlobalState('minutes_until_followup');
  const [store_sms_start_offset, setStore_sms_start_offset] = useGlobalState('store_sms_start_offset');
  const [store_sms_stop_offset, setStore_sms_stop_offset] = useGlobalState('store_sms_stop_offset');
  const [seconds_failure_retry_wait, setSeconds_failure_retry_wait] = useGlobalState('seconds_failure_retry_wait');
  const [max_failure_retries, setMax_failure_retries] = useGlobalState('max_failure_retries');
  const [sms_phone_number_tracking_expiration, setSms_phone_number_tracking_expiration] = useGlobalState('sms_phone_number_tracking_expiration');
  const [task, setTask] = useGlobalState('task');
  const [editMode, setEditMode] = useGlobalState('editMode');
  const [web_lead_auto_nuture_check_enabled, setWeb_lead_auto_nuture_check_enabled] = useGlobalState('web_lead_auto_nuture_check_enabled');
  const [web_lead_non_routing_check_enabled, setWeb_lead_non_routing_check_enabled] = useGlobalState('web_lead_non_routing_check_enabled');
  const [web_lead_self_progress_check_enabled, setWeb_lead_self_progress_check_enabled] = useGlobalState('web_lead_self_progress_check_enabled');
  ////These are for the Legacy Switches
  const [leadNutureCheck, setLeadNutureCheck] = useGlobalState('web_lead_auto_nuture_check_enabled');
  const [leadRoutingCheck, setLeadRoutingCheck] = useGlobalState('web_lead_non_routing_check_enabled');
  const [leadProgressCheck, setLeadProgressCheck] = useGlobalState('web_lead_self_progress_check_enabled');

  const handleLeadNutureCheckChange = (event) => {
    setLeadNutureCheck(event.target.checked);
  };

  const handleLeadRoutingCheckChange = (event) => {
    setLeadRoutingCheck(event.target.checked);
  };

  const handleLeadProgressCheckChange = (event) => {
    setLeadProgressCheck(event.target.checked);
  };

  // Max Num Executions Slider__________
  const handleMaxNumExecSliderChange = (event, newValue) => {
    setMax_num_executions(newValue);
  };

  const handleMaxNumExecInputChange = (event) => {
    setMax_num_executions(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleMaxNumExecBlur = () => {
    if (max_num_executions < 0) {
      setMax_num_executions(0);
    } else if (max_num_executions > 1) {
      setMax_num_executions(1);
    }
  };

  // Max Num SMS Followups__________
  const handleMaxSmsFollowupSliderChange = (event, newValue) => {
    setMax_num_sms_followups(newValue);
  };

  const handleMaxSmsFollowupInputChange = (event) => {
    setMax_num_sms_followups(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleMaxSmsFollowupBlur = () => {
    if (max_num_sms_followups < 0) {
      setMax_num_sms_followups(0);
    } else if (max_num_sms_followups > 1) {
      setMax_num_sms_followups(1);
    }
  };

  // Minutes Until Followup__________
  const handleMinUntilFollowupSliderChange = (event, newValue) => {
    setMinutes_until_followup(newValue);
  };

  const handleMinUntilFollowupInputChange = (event) => {
    setMinutes_until_followup(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleMinUntilFollowupBlur = () => {
    if (minutes_until_followup < 0) {
      setMinutes_until_followup(0);
    } else if (minutes_until_followup > 1) {
      setMinutes_until_followup(1);
    }
  };

  //Store SMS Start Offset__________
  const handleStoreSmsStartupOffsetSliderChange = (event, newValue) => {
    setStore_sms_start_offset(newValue);
  };

  const handleStoreSmsStartupOffsetInputChange = (event) => {
    setStore_sms_start_offset(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleStoreSmsStartupOffsetBlur = () => {
    if (store_sms_start_offset < 0) {
      setStore_sms_start_offset(0);
    } else if (store_sms_start_offset > 1) {
      setStore_sms_start_offset(1);
    }
  };

  //Store SMS Stop Offset__________
  const handleStoreSmsStopOffsetSliderChange = (event, newValue) => {
    setStore_sms_stop_offset(newValue);
  };

  const handleStoreSmsStopOffsetInputChange = (event) => {
    setStore_sms_stop_offset(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleStoreSmsStopOffsetBlur = () => {
    if (store_sms_stop_offset < 0) {
      setStore_sms_stop_offset(0);
    } else if (store_sms_stop_offset > 1) {
      setStore_sms_stop_offset(1);
    }
  };

  //Seconds Failure Retry Wait__________
  const handleSecFailureRetryWaitSliderChange = (event, newValue) => {
    setSeconds_failure_retry_wait(newValue);
  };

  const handleSecFailureRetryWaitInputChange = (event) => {
    setSeconds_failure_retry_wait(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleSecFailureRetryWaitBlur = () => {
    if (seconds_failure_retry_wait < 0) {
      setSeconds_failure_retry_wait(0);
    } else if (seconds_failure_retry_wait > 1) {
      setSeconds_failure_retry_wait(1);
    }
  };

  //Max Failure Retry__________
  const handleMaxFailureRetrySliderChange = (event, newValue) => {
    setMax_failure_retries(newValue);
  };

  const handleMaxFailureRetryInputChange = (event) => {
    setMax_failure_retries(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleMaxFailureRetryBlur = () => {
    if (max_failure_retries < 0) {
      setMax_failure_retries(0);
    } else if (max_failure_retries > 1) {
      setMax_failure_retries(1);
    }
  };

  //SMS Phone Number Tracking Expiration__________
  const handleSmsNumTrackExpSliderChange = (event, newValue) => {
    setSms_phone_number_tracking_expiration(newValue);
  };

  const handleSmsNumTrackExpInputChange = (event) => {
    setSms_phone_number_tracking_expiration(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleSmsNumTrackExpyBlur = () => {
    if (sms_phone_number_tracking_expiration < 0) {
      setSms_phone_number_tracking_expiration(0);
    } else if (sms_phone_number_tracking_expiration > 1) {
      setSms_phone_number_tracking_expiration(1);
    }
  };

  //Task__________
  const handleTaskSliderChange = (event, newValue) => {
    setTask(newValue);
  };

  const handleTaskInputChange = (event) => {
    setTask(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleTaskBlur = () => {
    if (task < 0) {
      setTask(0);
    } else if (task > 1) {
      setTask(1);
    }
  };


  const label = { inputProps: { 'aria-label': 'Switch demo' } };




  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <DrawerCustom />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        {/* <Card className='signinCard' sx={{ minWidth: 1000 }}>
          <CardContent> */}
        <Accordion>
          <AccordionSummary
            onClick={setR2tValues}
            expandIcon={<ExpandMoreIcon onClick={setR2tValues} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography id="accordionLabel">Global R2T Controls</Typography>
            <Tooltip title="Provides a score that determines prioritization to CXC and CXA agents" sx={{ paddingTop: '0' }}>
              <IconButton>
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </AccordionSummary>
          <AccordionDetails>
            {editMode === true && window.location.pathname !== '/' ?
              <Grid container spacing={2} columns={12} alignItems="center">
                <Grid item xs={4}>
                  <Typography sx={{ marginRight: 1 }} >Maximum Number Executions</Typography>
                  <Slider
                    value={max_num_executions}
                    onChange={handleMaxNumExecSliderChange}
                    aria-labelledby="input-slider"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Input
                    value={max_num_executions}
                    size="small"
                    onChange={handleMaxNumExecInputChange}
                    onBlur={handleMaxNumExecBlur}
                    inputProps={{
                      step: 10,
                      min: 0,
                      max: 100,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography sx={{ marginRight: 1 }} >Maximum Number SMS Follow Ups</Typography>
                  <Slider
                    value={max_num_sms_followups}
                    onChange={handleMaxSmsFollowupSliderChange}
                    aria-labelledby="input-slider"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Input
                    value={max_num_sms_followups}
                    size="small"
                    onChange={handleMaxSmsFollowupInputChange}
                    onBlur={handleMaxSmsFollowupBlur}
                    inputProps={{
                      step: 10,
                      min: 0,
                      max: 100,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography sx={{ marginRight: 1 }} >Minutes Until Followup</Typography>
                  <Slider
                    value={minutes_until_followup}
                    onChange={handleMinUntilFollowupSliderChange}
                    aria-labelledby="input-slider"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Input
                    value={minutes_until_followup}
                    size="small"
                    onChange={handleMinUntilFollowupInputChange}
                    onBlur={handleMinUntilFollowupBlur}
                    inputProps={{
                      step: 10,
                      min: 0,
                      max: 100,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography sx={{ marginRight: 1 }} >Store SMS Start Offset</Typography>
                  <Slider
                    value={store_sms_start_offset}
                    onChange={handleStoreSmsStartupOffsetSliderChange}
                    aria-labelledby="input-slider"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Input
                    value={store_sms_start_offset}
                    size="small"
                    onChange={handleStoreSmsStartupOffsetInputChange}
                    onBlur={handleStoreSmsStartupOffsetBlur}
                    inputProps={{
                      step: 10,
                      min: 0,
                      max: 100,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography sx={{ marginRight: 1 }} >Store SMS Stop Offset</Typography>
                  <Slider
                    value={store_sms_stop_offset}
                    onChange={handleStoreSmsStopOffsetSliderChange}
                    aria-labelledby="input-slider"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Input
                    value={store_sms_stop_offset}
                    size="small"
                    onChange={handleStoreSmsStopOffsetInputChange}
                    onBlur={handleStoreSmsStopOffsetBlur}
                    inputProps={{
                      step: 10,
                      min: 0,
                      max: 100,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography sx={{ marginRight: 1 }} >Seconds Failure Retry Wait</Typography>
                  <Slider
                    value={seconds_failure_retry_wait}
                    onChange={handleSecFailureRetryWaitSliderChange}
                    aria-labelledby="input-slider"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Input
                    value={seconds_failure_retry_wait}
                    size="small"
                    onChange={handleSecFailureRetryWaitInputChange}
                    onBlur={handleSecFailureRetryWaitBlur}
                    inputProps={{
                      step: 10,
                      min: 0,
                      max: 100,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography sx={{ marginRight: 1 }} >Maximum Failure Retries</Typography>
                  <Slider
                    value={max_failure_retries}
                    onChange={handleMaxFailureRetrySliderChange}
                    aria-labelledby="input-slider"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Input
                    value={max_failure_retries}
                    size="small"
                    onChange={handleMaxFailureRetryInputChange}
                    onBlur={handleMaxFailureRetryBlur}
                    inputProps={{
                      step: 10,
                      min: 0,
                      max: 100,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography sx={{ marginRight: 1 }} >SMS Phone Number Tracking Expiration</Typography>
                  <Slider
                    value={sms_phone_number_tracking_expiration}
                    onChange={handleSmsNumTrackExpSliderChange}
                    aria-labelledby="input-slider"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Input
                    value={sms_phone_number_tracking_expiration}
                    size="small"
                    onChange={handleSmsNumTrackExpInputChange}
                    onBlur={handleSmsNumTrackExpyBlur}
                    inputProps={{
                      step: 10,
                      min: 0,
                      max: 100,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                  />
                </Grid>
              </Grid>
              : ""}
            {editMode === false && window.location.pathname !== '/' ?
              <Grid container spacing={2} columns={12} alignItems="center">
                <Grid item xs={4}>
                  <Typography sx={{ marginRight: 1 }} >Maximum Number Executions</Typography>
                  <Slider
                    disabled
                    value={max_num_executions}
                    onChange={handleMaxNumExecSliderChange}
                    aria-labelledby="input-slider"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Input
                    disabled
                    value={max_num_executions}
                    size="small"
                    onChange={handleMaxNumExecInputChange}
                    onBlur={handleMaxNumExecBlur}
                    inputProps={{
                      step: 10,
                      min: 0,
                      max: 100,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography sx={{ marginRight: 1 }} >Maximum Number SMS Follow Ups</Typography>
                  <Slider
                    disabled
                    value={max_num_sms_followups}
                    onChange={handleMaxSmsFollowupSliderChange}
                    aria-labelledby="input-slider"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Input
                    disabled
                    value={max_num_sms_followups}
                    size="small"
                    onChange={handleMaxSmsFollowupInputChange}
                    onBlur={handleMaxSmsFollowupBlur}
                    inputProps={{
                      step: 10,
                      min: 0,
                      max: 100,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography sx={{ marginRight: 1 }} >Minutes Until Followup</Typography>
                  <Slider
                    disabled
                    value={minutes_until_followup}
                    onChange={handleMinUntilFollowupSliderChange}
                    aria-labelledby="input-slider"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Input
                    disabled
                    value={minutes_until_followup}
                    size="small"
                    onChange={handleMinUntilFollowupInputChange}
                    onBlur={handleMinUntilFollowupBlur}
                    inputProps={{
                      step: 10,
                      min: 0,
                      max: 100,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography sx={{ marginRight: 1 }} >Store SMS Start Offset</Typography>
                  <Slider
                    disabled
                    value={store_sms_start_offset}
                    onChange={handleStoreSmsStartupOffsetSliderChange}
                    aria-labelledby="input-slider"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Input
                    disabled
                    value={store_sms_start_offset}
                    size="small"
                    onChange={handleStoreSmsStartupOffsetInputChange}
                    onBlur={handleStoreSmsStartupOffsetBlur}
                    inputProps={{
                      step: 10,
                      min: 0,
                      max: 100,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography sx={{ marginRight: 1 }} >Store SMS Stop Offset</Typography>
                  <Slider
                    disabled
                    value={store_sms_stop_offset}
                    onChange={handleStoreSmsStopOffsetSliderChange}
                    aria-labelledby="input-slider"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Input
                    disabled
                    value={store_sms_stop_offset}
                    size="small"
                    onChange={handleStoreSmsStopOffsetInputChange}
                    onBlur={handleStoreSmsStopOffsetBlur}
                    inputProps={{
                      step: 10,
                      min: 0,
                      max: 100,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography sx={{ marginRight: 1 }} >Seconds Failure Retry Wait</Typography>
                  <Slider
                    disabled
                    value={seconds_failure_retry_wait}
                    onChange={handleSecFailureRetryWaitSliderChange}
                    aria-labelledby="input-slider"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Input
                    disabled
                    value={seconds_failure_retry_wait}
                    size="small"
                    onChange={handleSecFailureRetryWaitInputChange}
                    onBlur={handleSecFailureRetryWaitBlur}
                    inputProps={{
                      step: 10,
                      min: 0,
                      max: 100,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography sx={{ marginRight: 1 }} >Maximum Failure Retries</Typography>
                  <Slider
                    disabled
                    value={max_failure_retries}
                    onChange={handleMaxFailureRetrySliderChange}
                    aria-labelledby="input-slider"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Input
                    disabled
                    value={max_failure_retries}
                    size="small"
                    onChange={handleMaxFailureRetryInputChange}
                    onBlur={handleMaxFailureRetryBlur}
                    inputProps={{
                      step: 10,
                      min: 0,
                      max: 100,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography sx={{ marginRight: 1 }} >SMS Phone Number Tracking Expiration</Typography>
                  <Slider
                    disabled
                    value={sms_phone_number_tracking_expiration}
                    onChange={handleSmsNumTrackExpSliderChange}
                    aria-labelledby="input-slider"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Input
                    disabled
                    value={sms_phone_number_tracking_expiration}
                    size="small"
                    onChange={handleSmsNumTrackExpInputChange}
                    onBlur={handleSmsNumTrackExpyBlur}
                    inputProps={{
                      step: 10,
                      min: 0,
                      max: 100,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                  />
                </Grid>
              </Grid>
              : ""}
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            onClick={setR2tLegacyCntrls}
            expandIcon={<ExpandMoreIcon onClick={setR2tLegacyCntrls} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography id="accordionLabel">Legacy Controls</Typography>
            <Tooltip title="Provides a score that determines prioritization to CXC and CXA agents" sx={{ paddingTop: '0' }}>
              <IconButton>
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </AccordionSummary>
          <AccordionDetails>
            {editMode === true && window.location.pathname !== '/' ?
              <Grid>
                <Typography sx={{ marginRight: 1 }} >Web Lead Auto Nuture Check </Typography>
                <Switch checked={leadNutureCheck} onChange={handleLeadNutureCheckChange} inputProps={{ 'aria-label': 'controlled' }} />
                <Typography sx={{ marginRight: 1 }} >Web Lead Non Routing Check </Typography>
                <Switch checked={leadRoutingCheck} onChange={handleLeadRoutingCheckChange} inputProps={{ 'aria-label': 'controlled' }} />
                <Typography sx={{ marginRight: 1 }} >Web Lead Self Progess Check</Typography>
                <Switch checked={leadProgressCheck} onChange={handleLeadProgressCheckChange} inputProps={{ 'aria-label': 'controlled' }} />
              </Grid>
              : ""}
            {editMode === false && window.location.pathname !== '/' ?
              <Grid>
                <Typography sx={{ marginRight: 1 }} >Web Lead Auto Nuture Check </Typography>
                <Switch checked={leadNutureCheck} onChange={handleLeadNutureCheckChange} inputProps={{ 'aria-label': 'controlled' }} disabled />
                <Typography sx={{ marginRight: 1 }} >Web Lead Non Routing Check </Typography>
                <Switch checked={leadRoutingCheck} onChange={handleLeadRoutingCheckChange} inputProps={{ 'aria-label': 'controlled' }} disabled />
                <Typography sx={{ marginRight: 1 }} >Web Lead Self Progess Check</Typography>
                <Switch checked={leadProgressCheck} onChange={handleLeadProgressCheckChange} inputProps={{ 'aria-label': 'controlled' }} disabled />
              </Grid>
              : ""}
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
}
