import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { setGlobalState, useGlobalState } from "../state";
import DrawerCustom from '../component/Drawer';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import axios from "axios";
import { Divider } from "@mui/material";


export default function MonitoringWFM() {
    const [high_threshold, set_high_threshold] = useState('')
    const [medium_threshold, set_medium_threshold] = useState('')
    const [high_initial_priority, set_high_initial_priority] = useState('')
    const [high_max_priority, set_high_max_priority] = useState('')
    const [medium_initial_priority, set_medium_initial_priority] = useState('')
    const [medium_max_priority, set_medium_max_priority] = useState('')
    const [low_initial_priority, set_low_initial_priority] = useState('')
    const [low_max_priority, set_low_max_priority] = useState('')


    const getImConfigData = async () => {
        let params = { name: "all" }
        let result = await axios.get('/api/az_func_im_config', { params });
        sessionStorage.setItem("ImConfigData", JSON.stringify(result.data))
        let data = sessionStorage.getItem("ImConfigData")
        setGlobalState("imConfigData", JSON.parse(data))
        if (result.data === null || result.data === "") {
          result = { data: "No record found with that name" }
        }
        return result
      }
    
    
      const setValues = async () => {
        let result = await getImConfigData()
        for (const i in result.data) {
          if (result.data[i].name == "inbound_voice_general") {
            set_high_threshold(result.data[i].data.cec_sales_phone_scoring.priority_scoring.high_threshold)
            set_medium_threshold(result.data[i].data.cec_sales_phone_scoring.priority_scoring.medium_threshold)
            set_high_initial_priority(result.data[i].data.cec_sales_phone_scoring.priority_scoring.groups.high.initial_priority)
            set_high_max_priority(result.data[i].data.cec_sales_phone_scoring.priority_scoring.groups.high.max_priority)
            set_medium_initial_priority(result.data[i].data.cec_sales_phone_scoring.priority_scoring.groups.medium.initial_priority)
            set_medium_max_priority(result.data[i].data.cec_sales_phone_scoring.priority_scoring.groups.medium.max_priority)
            set_low_initial_priority(result.data[i].data.cec_sales_phone_scoring.priority_scoring.groups.low.initial_priority)
            set_low_max_priority(result.data[i].data.cec_sales_phone_scoring.priority_scoring.groups.low.max_priority)
          } 
        }
      }

      const styles = {
        text: {
          textShadow: '.5px .5px 1px black',
          color: '#2375fa',
        }
      }

      useEffect(() => {
        setValues()
      },[]);
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <DrawerCustom />
      <Grid container>
        <Grid xs={12}><Typography variant="h5" sx={{fontWeight: 'bold'}}>Priority Group Scores</Typography></Grid>
        <Grid xs={12}><Divider sx={{width: '80%', marginBottom: '1%'}}/></Grid>
        <Grid xs={3} sx={{marginRight:'2%'}}>
        <Paper elevation={3} sx={{display: 'flex', padding:'2%'}}>
        <Grid size={6} sx={{padding:'1%', marginLeft:'15%'}}>
        <center>
        <Typography variant="h6" gutterBottom sx={{fontWeight: 'bold'}} >
        Initial Priority High
        </Typography>
        <Typography variant="h1" gutterBottom style={styles.text}>
        {high_initial_priority}
        </Typography>
        </center>
        </Grid>
        <Grid size={3} sx={{padding:'1%'}}>
        <center>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
        Max Priority High
        </Typography>
        <Typography variant="h1" gutterBottom style={styles.text}>
        {high_max_priority}
        </Typography>
        </center>    
        </Grid>
        </Paper>
        </Grid>

        <Grid xs={3} sx={{marginRight:'2%'}}>
        <Paper elevation={3} sx={{display: 'flex', padding:'2%'}}>
        <Grid size={6} sx={{padding:'1%', marginLeft:'10%'}}>
        <center>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
        Initial Priority Medium
        </Typography>
        <Typography variant="h1" gutterBottom style={styles.text}>
        {medium_initial_priority}
        </Typography>
        </center>
        </Grid>
        <Grid size={3} sx={{padding:'1%'}}>
          <center>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
        Max Priority Medium
        </Typography>
        <Typography variant="h1" gutterBottom style={styles.text}>
        {medium_max_priority}
        </Typography> 
        </center>   
        </Grid>
        </Paper>
        </Grid>

        <Grid xs={3}>
        <Paper elevation={3} sx={{display: 'flex', padding:'2%'}}>
        <Grid size={6} sx={{padding:'1%', marginLeft:'17%'}}>
          <center>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
        Initial Priority Low
        </Typography>
        <Typography variant="h1" gutterBottom style={styles.text}>
        {low_initial_priority}
        </Typography>
        </center>
        </Grid>
        <Grid size={3} sx={{padding:'1%'}}>
          <center>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
        Max Priority Low
        </Typography>
        <Typography variant="h1" gutterBottom style={styles.text}>
        {low_max_priority}
        </Typography>
        </center>    
        </Grid>
        </Paper>
        </Grid>
        <Grid xs={12}><Typography variant="h5" sx={{marginTop:'2%', fontWeight: 'bold' }}>Priority Group Thresholds</Typography></Grid>
        <Grid xs={12}><Divider sx={{width: '80%', marginBottom: '1%'}}/></Grid>
        <Grid xs={8} sx={{}}>
        <Paper elevation={3} sx={{display: 'flex', padding:'2%'}}>
        <Grid size={6} sx={{padding:'1%', marginLeft:'25%'}}>
          <center>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
        Priority Group High Threshold
        </Typography>
        <Typography variant="h1" gutterBottom style={styles.text}>
            {high_threshold}
        </Typography>
        </center>
        </Grid>
        <Grid size={6} sx={{padding:'1%'}}>
          <center>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
        Priority Group Medium Threshold
        </Typography>
        <Typography variant="h1" gutterBottom style={styles.text}>
            {medium_threshold}
        </Typography>  
        </center>  
        </Grid>
        </Paper>
        </Grid>
      </Grid>

    </Box>
  );
}



